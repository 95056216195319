module.exports = {
    changeHost(domain) {
        // 表单作品内嵌数据分析页面， 必须走域名转换，不然拿不到sessionId 会跳转到frame首页,
        // this.$route.query.source === 'fl_editor'
        const s = window.location.search
        const isFormEditorAnalysis = s && s.includes('source=fl_editor')
        if (window.top !== window && !isFormEditorAnalysis) {
            return
        }
        domain = domain.toLowerCase()
        const url = window.location.href
        const urlHostname = window.location.hostname
        if (this.name === 'local') return
        if (urlHostname !== domain) {
            window.location.href = url.replace(urlHostname, domain)
        }
        if (this.isReplaced()) {
            return
        }
        const hostKeys = Object.keys(this.host)
        for (const key of hostKeys) {
            if (key === 'grantHost') {
                continue
            }
            this.host[key] = this.host[key].replace(this.hostname.split(':')[0], domain)
        }
    },
    isReplaced() {
        if (this.host.globalHOST.includes(this.hostname)) {
            return false
        }
        return true
    },
    addPublicPath() {
        if (CUSTOM_PUBLIC_PATH) {
            window.HOST = {
                HOST: this.host.globalHOST + CUSTOM_PUBLIC_PATH
            }
            // this.host.apiHost = this.host.apiHost.slice(0, -4) + CUSTOM_PUBLIC_PATH + this.host.apiHost.slice(-4)
            const hostKeys = Object.keys(this.host)
            const jumpHostList = ['grantHost', 'file', 'staticHost']
            for (const key of hostKeys) {
                if (jumpHostList.includes(key)) {
                    continue
                }
                if (key === 'apiHost') {
                    this.host[key] = this.host[key].slice(0, -4) + CUSTOM_PUBLIC_PATH + this.host[key].slice(-4)
                    continue
                }
                if (key === 'languageHost') {
                    this.host[key] = this.host[key].slice(0, -5) + CUSTOM_PUBLIC_PATH + this.host[key].slice(-5)
                    continue
                }
                this.host[key] = this.host[key] + CUSTOM_PUBLIC_PATH
            }
        }
    },
    initHost() {
        const urlDomin = window.location.hostname
        this.addPublicPath()
        this.changeHost(urlDomin)
    }
}
