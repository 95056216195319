const hostMethods = require('./hostMethods')
module.exports = {
    ...hostMethods,
    name: 'test',
    cdn: 'localhost',
    hostname: 'www.eqshow.cn',
    host: {
        // 微信绑定公众号使用，勿删！
        grantHost: '//www.eqshow.cn/',
        STORE: '//eqxiu.com',
        cdn: 'localhost',
        lpHOST: '//lpservice.eqxiu.com/',
        PRE_VIEW_LS: '//www.eqshow.cn/',
        globalHOST: '//www.eqshow.cn/',
        apiHost: '//www.eqshow.cn/api/',
        file: '//test-asset.eqxiu.cn/',
        staticHost: '/',
        languageHost: '//www.eqshow.cn/i18n/'
    },
    config: {
        wbAppkey: 3508809852
    },
    plugin: {
        vue: '//asset.eqxiu.cn/libs/vue/v1/vue.runtime.min.js',
        vueRouter: '//asset.eqxiu.cn/libs/vue-router/v1/vue-router.min.js',
        axios: '//asset.eqxiu.cn/libs/axios/v1/axios.min.js'
    }
}
